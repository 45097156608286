<template>
  <HeaderSection />
  <PresentationSection />
  <InformationSection />
  <CountDownToBeta />
  <DiscoverPartPresentation />
  <BrevoWaitlist />
</template>

<script>
import HeaderSection from './HeaderSection.vue';
import CountDownToBeta from './CountDownToBeta.vue';
import InformationSection from './InformationSection.vue';
import PresentationSection from './PresentationSection.vue';
import DiscoverPartPresentation from './DiscoverPartPresentation.vue';
import BrevoWaitlist from './BrevoWaitlist.vue';

export default {
  name: 'MainPage',
  components: {
    HeaderSection,
    CountDownToBeta,
    InformationSection,
    DiscoverPartPresentation,
    PresentationSection,
    BrevoWaitlist,
  }
}
</script>
