<template>
  <div class="count-down">
    <div class="count-down__separator"/>
    <span class="count-down__title">
      La bêta est <span style="color:#FABC2A">en cours</span> ! 
    </span>
    <span class="count-down__subtitle">
      Si vous souhaitez participer, <b class="count-down__subtitle--link" @click="redirectToForm">c'est par ici !</b>
    </span>
    <div class="count-down__separator down"/>
  </div>
</template>

<script>
export default {
  name: 'CountDownToBeta',
  methods: {
    redirectToForm() {
      window.location = '/#mail-waitlist';
    },
  }
}
</script>

<style lang="scss" scoped>
.count-down {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: black;
  padding-bottom: 25px;

  &__separator {
    width: 150px;
    height: 0.5px;
    background-color: #FABC2A;
    margin-bottom: 60px;
  }

  &__title {
    font-size: 35px;
    font-weight: 600;
    color: white;
    margin-bottom: 20px;
    padding: 0 50px;

    @media (max-width: 650px) {
      padding: 0 30px;
    }
  }

  &__subtitle {
    font-size: 25px;
    color: white;

    &--link {
      cursor: pointer;
    }
  }
}

.down {
  margin-top: 60px;
  margin-bottom: 0;
}
</style>