<template>
  <div class="signal__content">
    <span class="signal__content__title">Comment signaler un compte ou une publication Nohyia ?</span>
    <span class="signal__content__text">Vous avez un problème avec Nohyia ? Contactez l’assistance et dites-nous comment nous pouvons vous aider sur l'email suivant : <b>contact@nohyia.com</b>.</span><br/>
    <span class="update-delete-data__content__subtitle--description">
      Si vous possédez un compte Nohyia, vous pouvez depuis l'application mobile, signaler un profil ou un contenu qui enfreint nos <a href="https://nohyia.com/confidentiality/cgu">Règles de la communauté</a>.
    </span><br/>

    <span class="signal__content__subtitle">Actuellement, vous pouvez signaler une publication pour les raisons suivantes :</span><br/>
    <span class="signal__content__text">
      -&nbsp;&nbsp;Je n’aime tout simplement pas<br/>
      -&nbsp;&nbsp;Nudité et activités sexuelles<br/>
      -&nbsp;&nbsp;Discours ou symboles haineux<br/>
      -&nbsp;&nbsp;Violence ou organisations dangereuses<br/>
      -&nbsp;&nbsp;Diffamation<br/>
      -&nbsp;&nbsp;Discours haineux, harcèlement<br/>
      -&nbsp;&nbsp;Atteintes aux droits de propriété intellectuelle<br/>
    </span><br/>

    <span>Souvenez-vous que vos signalements sont anonymes, sauf si vous signalez une violation de propriété intellectuelle. Le compte que vous signalez ne sait pas qui l’auteur du signalement.</span><br/>

    <span class="signal__content__subtitle"> Signaler un compte ou une publication depuis le Fil :</span><br/>
    <span>
      -&nbsp;&nbsp;Cliquez sur le nom de l'artiste sur la publication, cela vous redirigera sur son profil.<br/>
      -&nbsp;&nbsp;Cliquez sur l'icon en haut à droite du profil.<br/>
      -&nbsp;&nbsp;Sélectionnez Signaler, puis suivez les instructions qui s’affichent.<br/>
    </span><br/>

    <span class="signal__content__subtitle">Signaler un compte ou une publication depuis la page des favories :</span><br/>
    <span>
      -&nbsp;&nbsp;Sélectionnez l'aperçu que vous souhaitez signaler (ou de la personne que vous souhaitez signaler).<br/>
      -&nbsp;&nbsp;Cliquez sur le nom de l'artiste sur la publication, cela vous redirigera sur son profil.<br/>
      -&nbsp;&nbsp;Cliquez sur l'icon en haut à droite du profil.<br/>
      -&nbsp;&nbsp;Sélectionnez Signaler, puis suivez les instructions qui s’affichent.<br/>
    </span>
  </div>
</template>

<script>
export default {
  name: 'SignalAccount',
}
</script>

<style lang="scss">
.spacing {
  margin-top: 10px;
}

.signal {
  &__content {
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    padding: 30px 50px;
    font-size: 14px;

    &__title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 10px;
    }

    &__subtitle {
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 10px;
    }

    &__text {
      font-size: 14px;
    }
  }
}
</style>
