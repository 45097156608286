<template>
  <div class="block__content">
    <span class="block__content__title">Comment bloquer un compte Nohyia ?</span>
    <span class="block__content__text">Vous avez un problème avec Nohyia ? Contactez l’assistance et dites-nous comment nous pouvons vous aider sur l'email suivant : <b>contact@nohyia.com</b>.</span><br/>

    <span class="block__content__text">
      Si vous possédez un compte Nohyia, vous pouvez depuis l'application mobile, bloquer un profil à tout moment.
    </span>
    <span>Lorsque vous bloqué un profil sur Nohyia, vous ne verrez plus son contenu, et lui non plus ne verra plus les votres.</span>
    <span>Si vous souhaitez débloquer un compte, veuillez nous contacter sur <b>contact@nohyia.com</b>.</span>
    <span class="delete-account__content__text spacing">Pour bloquer un compte Nohyia, suivez les étapes ci-dessous.</span><br/>

    <span class="block__content__subtitle"> Bloquer un compte depuis le Fil :</span><br/>
    <span>
      -&nbsp;&nbsp;Cliquez sur le nom de l'artiste sur la publication, cela vous redirigera sur son profil.<br/>
      -&nbsp;&nbsp;Cliquez sur l'icon en haut à droite du profil.<br/>
      -&nbsp;&nbsp;Sélectionnez Blouqer, puis suivez les instructions qui s’affichent.<br/>
    </span><br/>

    <span class="block__content__subtitle">Bloquer un compte depuis la page des favories :</span><br/>
    <span>
      -&nbsp;&nbsp;Sélectionnez l'aperçu que vous souhaitez bloquer (ou de la personne que vous souhaitez bloquer).<br/>
      -&nbsp;&nbsp;Cliquez sur le nom de l'artiste sur la publication, cela vous redirigera sur son profil.<br/>
      -&nbsp;&nbsp;Cliquez sur l'icon en haut à droite du profil.<br/>
      -&nbsp;&nbsp;Sélectionnez Bloquer, puis suivez les instructions qui s’affichent.<br/>
    </span>
  </div>
</template>

<script>
export default {
  name: 'BlockAccount',
}
</script>

<style lang="scss">
.spacing {
  margin-top: 10px;
}

.block {
  &__content {
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    padding: 30px 50px;
    font-size: 14px;

    &__title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 10px;
    }

    &__subtitle {
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 10px;
    }

    &__text {
      font-size: 14px;
    }
  }
}
</style>
