<template>
  <div class="update-delete-data__content">
    <span class="update-delete-data__content__title">Comment modifier ou supprimer ses données sur Nohyia ?</span>
    <span class="update-delete-data__content__text">Vous avez un problème avec Nohyia ? Contactez l’assistance et dites-nous comment nous pouvons vous aider sur l'email suivant : <b>contact@nohyia.com</b>.</span>
    <span class="update-delete-data__content__text spacing">Pour modifier ou supprimer vos données Nohyia, suivez les étapes ci-dessous. Cela modifiera ou supprimera vos données définitivement.</span><br/>

    <span class="update-delete-data__content__subtitle">Pour modifier votre compte Nohyia sur IOS ou Android.</span>

    <span class="update-delete-data__content__text">
      -&nbsp;&nbsp;Connectez-vous sur votre compte Nohyia. <br/>
      -&nbsp;&nbsp;Allez dans le menu déroulant dans la page profil.<br/>
      -&nbsp;&nbsp;Appuyez sur « Modifier le compte ».<br/>
      -&nbsp;&nbsp;Appuyez sur « Confirmer » pour confirmer toute les modifications du compte faites dans le formulaire.<br/>
    </span><br/>

    <span class="update-delete-data__content__subtitle">Pour modifier vos aperçus sur IOS ou Android.</span>

    <span class="update-delete-data__content__text">
      -&nbsp;&nbsp;Connectez-vous sur votre compte Nohyia. <br/>
      -&nbsp;&nbsp;Sélectionnez un aperçu depuis votre page profil.<br/>
      -&nbsp;&nbsp;Appuyez sur les trois petits points en haut à droite de la page d'aperçu.<br/>
      -&nbsp;&nbsp;Sélectionnez "Modifier" dans le menu déroulant.<br/>
      -&nbsp;&nbsp;Appuyez sur « Confirmer » pour confirmer toute les modifications faites dans le formulaire.<br/>
    </span><br/>

    <span class="update-delete-data__content__subtitle">Pour supprimer vos aperçus sur IOS ou Android.</span>

    <span class="update-delete-data__content__text">
      -&nbsp;&nbsp;Connectez-vous sur votre compte Nohyia. <br/>
      -&nbsp;&nbsp;Sélectionnez un aperçu depuis votre page profil.<br/>
      -&nbsp;&nbsp;Appuyez sur les trois petits points en haut à droite de la page d'aperçu.<br/>
      -&nbsp;&nbsp;Sélectionnez "Supprimer" dans le menu déroulant.<br/>
      -&nbsp;&nbsp;Appuyez sur « Confirmer » pour confirmer la suppression de votre aperçu (Attention celui-ci sera définitivement supprimé).<br/>
    </span><br/>

    <span class="update-delete-data__content__subtitle">Pour retirer vos aperçus du public sur IOS ou Android.</span>

    <span class="update-delete-data__content__text">
    -&nbsp;&nbsp;Connectez-vous sur votre compte Nohyia. <br/>
    -&nbsp;&nbsp;Sélectionnez un aperçu depuis votre page profil.<br/>
    -&nbsp;&nbsp;Appuyez sur les trois petits points en haut à droite de la page d'aperçu.<br/>
    -&nbsp;&nbsp;Sélectionnez "Retirer du public" dans le menu déroulant.<br/>
    -&nbsp;&nbsp;Appuyez sur « Confirmer » pour confirmer la suppression de votre aperçu du public.<br/>
    </span>
  </div>
</template>
  
  <script>
  export default {
    name: 'UpdateDeleteDataPage',
  }
  </script>
  
  <style lang="scss">
  .spacing {
    margin-top: 10px;
  }
  
  .update-delete-data {
    &__content {
      background-color: white;
      color: black;
      display: flex;
      flex-direction: column;
      padding: 30px 50px;
  
      &__title {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 10px;
      }
  
      &__subtitle {
        font-weight: bold;
        font-size: 15px;
        margin-bottom: 10px;
      }
  
      &__text {
        font-size: 14px;
      }
    }
  }
  </style>
  