<template>
  <div class="header-section">
    <img src="@/assets/background.png" alt="Nohyia logo" class="header-section__background" />
    <div class="header-section__header">
      <span class="header-section__header__logo">NOHYIA</span>
      <div
        class="header-section__header--cta"
        @click="redirectToForm"
      >
        <span>Participer à la bêta</span>
      </div>
    </div>
    <div class="header-section__content">
      <div class="header-section__content__info">
        <span class="header-section__content__info--label">
          Faites découvrir votre musique localement
        </span>
        <span class="header-section__content__info--label-sub">
          Partagez votre musique grâce à des extraits de 30 secondes et une recherche par géolocalisation.
        </span>
        <div
          class="header-section__header--cta-filled"
          @click="redirectToForm"
        >
          <span>Participer à la bêta</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderSection',
  methods: {
    redirectToForm() {
      window.location = '/#mail-waitlist';
    },
  }
}
</script>

<style lang="scss" scoped>
.header-section {
  height: 100vh;
  position: relative;
  background-color: black;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    &__logo {
      font-size: 50px;
      font-weight: bold;
      color: #FABC2A;

      @media (max-width: 650px) {
        font-size: 40px;
      }
    }

    &--cta {
      padding: 5px 10px;
      border: solid 2px #FABC2A;
      border-radius: 5px;
      background-color: transparent;
      color: #FABC2A;
      font-size: 20px;
      font-weight: 400;
      cursor: pointer;
      z-index: 5;

      &-filled {
        margin-top: 60px;
        padding: 10px 20px;
        border-radius: 5px;
        background-color: #FABC2A;
        color: white;
        font-size: 20px;
        font-weight: 500;
        cursor: pointer;
      }

      @media (max-width: 650px) {
        font-size: 12px;
      }
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    opacity: 0.6;
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    position: absolute;

    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 30px;
      width: 48%;

      @media (max-width: 674px) {
        width: 90%;
      }

      &--label {
        font-size: 40px;
        font-weight: 600;
        color: white;

        &-sub {
          font-size: 30px;
          font-weight: 400;
          color: white;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
