<template>
  <div class="information-section">
    <div class="information-section__mock-up">
      <img src="@/assets/mockup-artiste-page.png" alt="artiste" />
    </div>
    <div class="information-section__description">
      <span class="information-section__description__title">
        <span style="color:#FABC2A">Élargissement</span> de l'audience
      </span>
      <span class="information-section__description__title--sub">
        Élargissez votre <span style="color:#FABC2A">base de fans locaux</span> en mettant en avant vos créations musicales à travers des <span style="color:#FABC2A">prévisualisations de 30 secondes</span>, augmentant ainsi votre visibilité dans la région.
      </span>
      <div
        class="information-section__description--cta"
        @click="redirectToForm"
      >
        <span>Participer à la bêta</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InformationSection',
  methods: {
    redirectToForm() {
      window.location = '/#mail-waitlist';
    },
  }
}
</script>

<style lang="scss" scoped>
.information-section {
  display: flex;
  flex-direction: row;
  padding: 40px;
  background-color: black;

  @media (max-width: 765px) {
    flex-direction: column;
    padding: 40px 55px 60px 30px;
  }

  &__description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: white;
    width: 100%;

    &__title {
      font-size: 40px;
      font-weight: 600;

      &--sub {
        font-size: 20px;
        font-weight: 400;
        margin-top: 20px;
      }
    }

    &--cta {
      margin-top: 20px;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      background-color: black;
      border: solid 2px #FABC2A;
      color: white;
      font-size: 15px;
      font-weight: 400;
      cursor: pointer;
      color: #FABC2A;
    }
  }

  &__mock-up {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    img {
      width: 60%;
      object-fit: cover;
      min-width: 215px;

      @media (max-width: 765px) {
        margin-bottom: 30px;
      }
    }
  }
}
</style>