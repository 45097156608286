<template>
  <div class="cgu">
    <div class="cgu__header">
      <div
        class="cgu__fixed-header"
        @click="$router.push('/')"
      >
        <span class="cgu__fixed-header__logo">NOHYIA</span>
      </div>
      <span>Conditions Générales d'Utilisation</span>
    </div>
    <div class="cgu__content">
      <div class="cgu__panel">
        <span class="cgu__panel--summary">Sommaire</span>
        <span
          v-for="(politic, index) in politics"
          :key="index"
          class="cgu__panel--label"
        >
          <a
            :href="`#collapse-${index}`"
            class="cgu__panel--link"
          >
            {{politic.label}}
          </a>
        </span>
      </div>
      <div class="cgu__body">
        <div
          v-for="(politic, index) in politics"
          :key="index"
          :id="`collapse-${index}`"
          class="cgu__collapse"
        >
          <div
            :id="`cgu__collapse__title-${index}`"
            class="cgu__collapse__title"
          >
            <span>{{politic.label}}</span>
          </div>
          <div
            :id="`collapse-container-${index}`"
            class="cgu__collapse__container expend"
          >
            <div
              class="cgu__collapse__content"
            >
              <span v-html="politic.value"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CguTerms',
  computed: {
    politics() {
      return [
        {
          label: "1. Acceptation des Conditions Générales d'Utilisation",
          value: "En utilisant l'application mobile Nohyia, vous acceptez pleinement et sans réserve les présentes Conditions Générales d'Utilisation (CGU). Si vous n'acceptez pas ces conditions, vous ne devez pas utiliser l'application."
        },
        {
          label: '2. Description du Service',
          value: "Nohyia permet aux utilisateurs de partager des extraits de 30 secondes de leurs compositions musicales. Les utilisateurs doivent posséder tous les droits sur les musiques qu'ils partagent via l'application."
        },
        { label: '3. Inscription',
          value: "Pour utiliser les services de Nohyia, les utilisateurs doivent créer un compte en fournissant des informations exactes et complètes. Chaque utilisateur est responsable de la confidentialité de ses identifiants de connexion et de toutes les activités réalisées depuis son compte."
        },
        {
          label: '4. Utilisation du Contenu',
          value: "\
            <b>4.1. Règles de Signalement et de Blocage</b><br><br>\
            Les utilisateurs de notre plateforme doivent respecter les règles suivantes. Le non-respect de ces règles peut entraîner un signalement ou un blocage de l'utilisateur.<br/></br> \
            <b>Je n’aime tout simplement pas :</b> Bien que nous encourageons la liberté d'expression, les contenus qui ne respectent pas les normes de notre communauté ou qui sont jugés inappropriés peuvent être signalés ou bloqués. </br></br>\
            <b>Nudité et activités sexuelles :</b> Les contenus contenant de la nudité ou des activités sexuelles explicites sont strictement interdits. Toute publication de ce type sera immédiatement retirée et l'utilisateur pourra être bloqué. </br></br>\
            <b>Discours ou symboles haineux :</b> Les contenus incitant à la haine ou utilisant des symboles haineux sont interdits. Cela inclut, sans s'y limiter, le racisme, l'antisémitisme, l'homophobie, et tout autre discours discriminatoire. </br></br>\
            <b>Violence ou organisations dangereuses :</b> Toute apologie de la violence, ainsi que la promotion ou l'appartenance à des organisations dangereuses ou terroristes, sont strictement prohibées. </br></br>\
            <b>Diffamation :</b> La diffusion de fausses informations visant à diffamer une personne ou une organisation est interdite et sera sanctionnée. </br></br>\
            <b>Discours haineux, harcèlement :</b> Tout discours haineux ou comportement de harcèlement envers un autre utilisateur est strictement interdit. Les utilisateurs doivent se comporter de manière respectueuse les uns envers les autres. </br></br>\
            <b>Atteintes aux droits de propriété intellectuelle :</b> Toute violation des droits de propriété intellectuelle, incluant le partage non autorisé de contenu protégé par des droits d'auteur, entraînera un signalement et potentiellement un blocage de l'utilisateur responsable. </br></br>\
            Ces règles sont mises en place pour garantir une expérience sûre et agréable pour tous les utilisateurs de notre plateforme. Le non-respect de ces règles peut entraîner des mesures disciplinaires, y compris la suspension ou le blocage permanent du compte utilisateur.<br/><br/>\
            <b>4.2. Propriété Intellectuelle</b><br><br>\
            Les utilisateurs doivent posséder tous les droits nécessaires sur les musiques qu'ils partagent via Nohyia. En partageant un extrait musical, l'utilisateur déclare et garantit qu'il est le propriétaire de tous les droits relatifs à ce contenu ou qu'il dispose des autorisations nécessaires pour le partager.<br><br>\
            <b>4.3. Licence Accordée à Nohyia</b><br><br>\
            En partageant un extrait musical, l'utilisateur accorde à Nohyia une licence mondiale, non-exclusive, transférable, sous-licenciable et sans redevance pour utiliser, reproduire, distribuer, préparer des œuvres dérivées, afficher et exécuter ce contenu en relation avec la fourniture des services de l'application.<br><br>\
          "
        },
        { label: "5. Comportement de l'Utilisateur",
          value: "Les utilisateurs s'engagent à ne pas :<br><br>\
          - Utiliser l'application à des fins illégales ou non autorisées.<br>\
          - Partager des contenus qui violent les droits de propriété intellectuelle de tiers.<br>\
          - Publier des contenus diffamatoires, offensants ou qui enfreignent la vie privée d'autrui.<br>\
          - Usurper l'identité de toute personne ou entité.<br>\
          "
        },
        { label: '6. Modération et Suppression de Contenu',
          value: "Nohyia se réserve le droit de modérer et de supprimer tout contenu qui enfreint les présentes CGU ou qui est jugé inapproprié. Les utilisateurs seront notifiés en cas de suppression de leur contenu."
        },
        { label: '7. Protection des Données Personnelles',
          value: "Nohyia s'engage à protéger les données personnelles des utilisateurs conformément à la législation en vigueur. Pour plus d'informations, veuillez consulter notre <a href='/confidentiality/political'>Politique de Confidentialité</a>."
        },
        { label: '8. Responsabilité',
          value: "Nohyia met tout en œuvre pour assurer un service de qualité, mais ne peut garantir l'absence d'interruptions ou d'erreurs. Nohyia ne saurait être tenue responsable de tout dommage direct ou indirect résultant de l'utilisation de l'application."
        },
        { label: '9. Modifications des CGU',
          value: "Nohyia se réserve le droit de modifier les présentes CGU à tout moment. Les modifications entreront en vigueur dès leur publication sur l'application. Les utilisateurs sont invités à consulter régulièrement les CGU pour être informés des éventuelles modifications."
        },
        { label: '10. Droit Applicable et Juridiction Compétente',
          value: "Les présentes CGU sont régies par le droit français. En cas de litige, les tribunaux français seront seuls compétents."
        },
        { label: '11. Contact',
          value: "Pour toute question relative aux présentes CGU, vous pouvez nous contacter à l'adresse suivante : contact@nohyia.com."
        },
        { label: '12. Droits et Obligations de Nohyia',
          value: "\
            - <b>Maintenance et Mises à Jour</b> : Nohyia peut être amenée à effectuer des opérations de maintenance ou des mises à jour qui peuvent rendre l'application temporairement indisponible. Nohyia informera les utilisateurs dans la mesure du possible.<br><br>\
            - <b>Améliorations et Nouveautés</b> : Nohyia se réserve le droit d'introduire de nouvelles fonctionnalités ou de supprimer des fonctionnalités existantes. <br><br>\
          "
        },
        { label: '13. Limitation de Responsabilité',
          value: "\
            - <b>Contenus des Utilisateurs</b> : Nohyia ne peut être tenue responsable des contenus partagés par les utilisateurs. Chaque utilisateur est seul responsable du contenu qu'il partage.<br><br>\
            - <b>Perte de Données</b> : Nohyia décline toute responsabilité en cas de perte de données suite à l'utilisation de l'application.<br><br>\
            - <b>Sécurité</b> : Bien que Nohyia mette en place des mesures de sécurité pour protéger les données des utilisateurs, elle ne peut garantir une sécurité absolue contre les attaques informatiques. <br><br>\
          "
        },
        { label: '14. Conditions de Résiliation',
          value: "\
            - <b>Par l'Utilisateur</b> : Les utilisateurs peuvent résilier leur compte à tout moment via l'application.<br><br>\
            - <b>Par Nohyia</b> : Nohyia se réserve le droit de suspendre ou de résilier un compte utilisateur en cas de violation des présentes CGU ou pour toute autre raison jugée légitime.<br><br>\
          "
        },
        { label: '15. Publicité et Promotion',
          value: "\
            - <b>Affichage de Publicité</b> : Nohyia se réserve le droit d'afficher des publicités au sein de l'application. Les utilisateurs acceptent que Nohyia puisse intégrer des annonces publicitaires dans les services proposés.<br><br>\
            - <b>Promotions</b> : Nohyia peut proposer des offres promotionnelles. Les conditions spécifiques de chaque promotion seront détaillées dans les communications correspondantes.<br><br>\
          "
        },
        { label: '16. Propriété et Marques',
          value: "\
            - <b>Propriété de l'Application</b> : Tous les éléments de l'application, y compris les textes, graphiques, logos, images, clips audio, sont la propriété de Nohyia ou de ses concédants de licence et sont protégés par les lois sur le droit d'auteur.<br><br>\
            - <b>Utilisation des Marques</b> : Les utilisateurs ne peuvent pas utiliser les marques de Nohyia sans autorisation préalable.<br><br>\
          "
        },
        { label: '17. Service Client',
          value: "\
            - <b>Support Technique</b> : Nohyia propose un service de support technique accessible via l'application ou par email à l'adresse support@nohyia.com.<br><br>\
            - <b>Réclamations</b> : Pour toute réclamation concernant l'utilisation de l'application, les utilisateurs peuvent contacter Nohyia à l'adresse ci-dessus.<br><br>\
          "
        },
        { label: '18. Notification',
          value: "\
            - <b>Moyens de Notification</b> : Nohyia peut contacter les utilisateurs par email, notification push, ou tout autre moyen de communication fourni par l'utilisateur lors de son inscription.<br><br>\
          "
        },
        { label: '19. Âge Minimum',
          value: "L'utilisation de l'application Nohyia est réservée aux personnes âgées de 13 ans et plus. En utilisant l'application, vous déclarez avoir au moins 13 ans. Les utilisateurs de moins de 13 ans ne sont pas autorisés à utiliser l'application."
        },
        { label: '20. Dispositions Finales',
          value: "\
            - <b>Indépendance des Clauses</b> : Si une disposition des présentes CGU est jugée invalide ou inapplicable, les autres dispositions resteront en vigueur.<br><br>\
            - <b>Accord Complet</b> : Les présentes CGU constituent l'intégralité de l'accord entre les utilisateurs et Nohyia concernant l'utilisation de l'application. <br><br>\
          "
        },
      ];
    }
  },
}
</script>

<style lang="scss">
.cgu {
  &__fixed-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    &__logo {
      font-size: 40px;
      font-weight: bold;
      color: #FABC2A;
      cursor: pointer;

      @media (max-width: 650px) {
        font-size: 40px;
      }
    }
  }

  &__header {
    display: flex;
    align-items: flex-end;
    height: 180px;
    padding: 30px 50px;
    background-color: black;
    color: white;
    font-size: 40px;
    font-weight: bold;
    position: relative;
  }

  &__content {
    background-color: white;
    color: black;
    display: flex;
    flex-direction: row;
  }

  &__panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 400px;
    background-color: #FABC2A;
    justify-content: flex-start;
    padding: 50px 20px 0 30px;

    @media (max-width: 780px) {
      display: none;
    }

    &--summary {
      font-weight: bold;
      margin-bottom: 25px;
      font-size: 25px;
    }

    &--label {
      margin-bottom: 20px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    &--link {
      color: black;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 95%;
  }

  &__collapse {
    border-radius: 5px;
    border: solid 2px black;
    margin-bottom: 25px;

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      font-size: 20px;
      font-weight: 600;
    }

    &__content {
      padding: 5px 20px 20px 20px;
    }
  }
}
</style>