<template>
  <div id="mail-waitlist" class="mail-waitlist">
    <span class="mail-waitlist__title">Participer à la bêta</span>
    <div class="mail-waitlist__iframe">
      <div id="mc_embed_shell">
        <div id="mc_embed_signup">
          <!-- Invisible iframe to avoid Mailchimp form submission redirection -->
          <iframe name="mailchimpFram" id="mailchimpFram" style="display: none;"></iframe>
          <form action="https://nohyia.us18.list-manage.com/subscribe/post?u=2057ae977bd56a2ea310aa65c&amp;id=757435f2a5&amp;f_id=00bc15e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="mailchimpFram" novalidate="">
            <div id="mc_embed_signup_scroll">
              <span class="form_title">Fournissez votre e-mail pour être dans les premiers à utiliser Nohyia en participant à la bêta.</span>
              <div class="mc-field-group">
                <input v-model="emailForm" type="email" name="EMAIL" class="required email" id="mce-EMAIL" />
                <span v-if="errorMessage" class="info-message">{{ errorMessage }}</span>
                <span id="mce-EMAIL-HELPERTEXT" class="helper_text">Ex: gerard@gmail.com</span>
              </div>
              <div aria-hidden="true" style="position: absolute; left: -5000px;">
                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups -->
                <input type="text" name="b_2057ae977bd56a2ea310aa65c_757435f2a5" tabindex="-1" value="" />
              </div>
              <span v-if="successMessage" class="info-message--success" v-html="successMessage"></span>
              <span class="informations">En fournissant votre email, vous acceptez que Nohyia vous re-contact pour vous informer de toute information à propos de la sortie de l'application.</span>
              <div class="clear foot">
                <input @click="submitForm()" type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="S'inscrire" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BrevoWaitlist',
  data() {
    return {
      email: '',
      errorMessage: null,
      successMessage: null
    }
  },
  computed: {
    emailForm: {
      get() {
        return this.email;
      },
      set(value) {
        this.email = value;
        this.emailIsValid();
      }
    }
  },
  methods: {
    emailIsValid() {
      const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email);

      if (!isValid) {
        this.errorMessage = 'Veuillez entrer une adresse e-mail valide.';
      } else {
        this.errorMessage = null;
      }
    },
    submitForm() {
      this.successMessage = '<b>Félicitation</b> ! Vous êtes inscrit à la bêta. <br> Vous recevrez un e-mail de confirmation bientôt avec toutes les informations nécessaires :).';
    }
  },
}
</script>

<style lang="scss">
.mail-waitlist {
  display: flex;
  flex-direction: column;
  background-color: #1d1f2a;
  padding: 40px;

  @media (max-width: 765px) {
    padding: 40px 55px 60px 30px;
  }

  &__title {
    font-size: 40px;
    font-weight: 600;
    color: white;
  }

  &__iframe {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }
}

#mc_embed_signup {
  background-color: rgba(39,44,58,1);
  padding: 20px;
  max-width: 500px;
}

.info-message {
  margin-top: 10px;
  margin-left: 2px;
  font-size: 12px;
  color: rgba(207, 102, 121, 1);

  &--success {
    color: rgba(102, 207, 121, 1);
    margin-bottom: 15px;
  }
}

.mc-field-group {
  margin-top: 15px;
}

.helper_text {
  margin-top: 5px;
  font-size: 12px !important;
  color: #adb8c8 !important;
  background-color: rgba(39,44,58,1) !important;
}

.form_title {
  font-size: 16px;
  color: white;
  margin-bottom: 20px;
  font-weight: 500;
}

#mc_embed_signup .button {
  font-size: 16px;
  text-align: right;
  font-weight: 700;
  font-family: "Helvetica", sans-serif;
  color: #FFFFFF;
  background-color: #fabc2a;
  border-radius: 3px;
  border-width: 0px;
  margin: 15px 0 0 0;
}

#mc_embed_signup_scroll {
  display: flex;
  flex-direction: column;
}

#mc_embed_signup .mc-field-group {
  width: 100%;
}

.informations {
  font-size: 12px;
  color: #adb8c8;
}

#mc_embed_signup .foot {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
