<template>
  <div class="discover-section">
    <div class="discover-section__description">
      <span class="discover-section__description__title">
        Découvrez <span style="color:#FABC2A">l'avenir</span> de la musique locale
      </span>
      <span class="discover-section__description__title--sub">
        Soyez parmi les premiers à découvrir les futurs talents musicaux de votre région en explorant les dernières sorties et les créations innovantes d’artistes émergents.
      </span>
      <div
        class="discover-section__description--cta"
        @click="redirectToForm"
      >
        <span>Participer à la bêta</span>
      </div>
    </div>
    <div class="discover-section__mock-up">
      <img src="@/assets/mockup-discover.png" alt="artiste" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DiscoverPartPresentation',
  methods: {
    redirectToForm() {
      window.location = '/#mail-waitlist';
    },
  }
}
</script>

<style lang="scss" scoped>
.discover-section {
  display: flex;
  flex-direction: row;
  padding: 50px 80px;
  background-color: black;

  @media (max-width: 865px) {
    flex-direction: column;
    padding: 50px 30px;
  }

  &__description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: white;
    width: 100%;
    margin-left: 30px;

    @media (max-width: 865px) {
      margin-left: 0;
    }

    &__title {
      font-size: 40px;
      font-weight: 600;

      &--sub {
        font-size: 20px;
        font-weight: 400;
        margin-top: 20px;
      }
    }

    &--cta {
      margin-top: 20px;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      background-color: black;
      border: solid 2px #FABC2A;
      color: white;
      font-size: 15px;
      font-weight: 400;
      cursor: pointer;
      color: #FABC2A;
    }
  }

  &__mock-up {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (max-width: 865px) {
      margin-top: 50px;
    }

    img {
      width: 40%;
      min-width: 240px;
      object-fit: cover;

      @media (max-width: 865px) {
        width: 240px;
      }
    }
  }
}
</style>