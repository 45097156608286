<template>
  <div class="confidentiality-politic">
    <div class="confidentiality-politic__header">
      <div
        class="confidentiality-politic__fixed-header"
        @click="$router.push('/')"
      >
        <span class="confidentiality-politic__fixed-header__logo">NOHYIA</span>
      </div>
      <span>Politique de confidentialité</span>
    </div>
    <div class="confidentiality-politic__content">
      <div class="confidentiality-politic__panel">
        <span class="confidentiality-politic__panel--summary">Sommaire</span>
        <span
          v-for="(politic, index) in politics"
          :key="index"
          class="confidentiality-politic__panel--label"
          @click="expandCollapse(index)"
        >
          {{politic.label}}
        </span>
      </div>
      <div class="confidentiality-politic__body">
        <div
          v-for="(politic, index) in politics"
          :key="index"
          :id="`collapse-${index}`"
          class="confidentiality-politic__collapse"
        >
          <div
            :id="`confidentiality-politic__collapse__title-${index}`"
            class="confidentiality-politic__collapse__title"
            @click="expandCollapse(index)"
          >
            <span>{{politic.label}}</span>
            <span>+</span>
          </div>
          <div
            :id="`collapse-container-${index}`"
            class="confidentiality-politic__collapse__container expend"
          >
            <div
              class="confidentiality-politic__collapse__content"
            >
              <span v-html="politic.value"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfidentialityPolitic',
  computed: {
    politics() {
      return [
        { label: 'Qui sommes nous ?', value: "L'objectif de Nohyia est de permettre à tous les artistes de pouvoir faire entendre leurs musiques, mais aussi permettre de découvrir de nouveaux artistes de manière ludique et innovante.<br><br> La présente Politique de confidentialité vous aidera à comprendre comment nous collectons, utilisons et partageons vos informations personnelles et vous aidera à exercer les droits de confidentialité dont vous disposez." },
        { label: 'Quelle donnée collectons nous et comment ?', value: "\
          Il est très important pour nous que vous compreniez quelles données personnelles nous collectons à votre sujet, comment nous les collectons et pourquoi elles sont nécessaires. \
          <br/><br/>\
          Nous collectons vos données personnelles de la manière suivante : <br/><br/>\
          <br><b>1. Lorsque vous vous inscrivez au service Nohyia ou que vous mettez à jour votre compte</b><br/> nous collectons certaines données personnelles pour créer votre compte Nohyia afin que vous puissiez utiliser le service Nohyia. Ces informations incluent votre nom de profil et votre adresse e-mail. <br/>\
          <br><b>2. Via votre utilisation du service Nohyia</b> <br/> lorsque vous accédez au service Nohyia ou que vous l'utilisez, nous collectons et traitons des données personnelles sur vos actions, par exemple les titres que vous écoutez et ceux que vous créez.<br/>\
          <br><b>3. Données personnelles que vous choisissez de nous fournir</b> <br/> de temps à autre, vous pouvez également nous transmettre des données personnelles supplémentaires ou nous autoriser à les collecter, par exemple pour vous proposer davantage de fonctionnalités. Ces informations pourraient notamment appartenir aux catégories Données de paiement et d'achat.<br/>\
          <br><b>4. Données personnelles que nous recevons de sources tierces</b> <br/> si vous vous inscrivez à Nohyia à l'aide d'un autre service, ou si vous connectez votre compte Nohyia à une application, un service ou un appareil tiers, ils nous communiquent vos données. Nous pouvons également collecter vos données auprès de fournisseurs de services techniques, ou de partenaires de paiement, publicitaires et marketing.<br/>\
        "},
        { label: 'Pourquoi avons nous besoin de vos données ?', value: "Nous faisons en sorte de collecter le moins de données possible. Cependant, pour vous permettre d'avoir une expérience optimal et de surtout pour le comportement minimum de l'application, nous devons enregistrer certaines informations. <br> <br> \
          <b>Les informations relatives aux musiques :</b>\
          Nous enregistrons uniquement les informations fournis dans le formulaire: Titre, Catégories, Musique, début de preview, Image.<br> <br>\
          <b>Les informations relative à l'utilisateur :</b> \
          Nous enregistrons les informations que vous nous fournissez via les formulaires: Avatar, pseudo, déscription, email, mot de passe, distance maximum, position (si selectionnée depuis la page paramètre), catégories favorites." },
        { label: 'Protection des informations', value: "La protection de vos informations est la chose la plus importante pour nous.<br><br>\
          - Toutes les requêtes sont encryptés avec un certificat SSL let's enscrypt.<br>\
          - La communication entre le client (application mobile) et le serveur, sont sécurisé via une authentification par JWT.<br>\
          - Nous vous demandons de fournir un mot de passe robuste de minimum 8 charactères.<br><br>\
          Il est très important pour nous de tout mettre en oeuvre pour protéger vos informations, et c'est ce que nous faisons tous les jours. Cependant nous ne pouvons que vous conseiller de ne <b>surtout pas partager vos accès</b>, tel que vos indentifients de connection (email et mot de passe)." },
      ];
    }
  },
  methods: {
    expandCollapse(index) {
      const container = document.getElementById(`collapse-container-${index}`)
      const title = document.getElementById(`confidentiality-politic__collapse__title-${index}`)

      title.classList.toggle('collapse')
      container.classList.toggle('expanded')
      container.classList.toggle('collapsed')
    }
  }
}
</script>

<style lang="scss">
.confidentiality-politic {
  &__fixed-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    &__logo {
      font-size: 40px;
      font-weight: bold;
      color: #FABC2A;
      cursor: pointer;

      @media (max-width: 650px) {
        font-size: 40px;
      }
    }
  }

  &__header {
    display: flex;
    align-items: flex-end;
    height: 180px;
    padding: 30px 50px;
    background-color: black;
    color: white;
    font-size: 40px;
    font-weight: bold;
    position: relative;
  }

  &__content {
    background-color: white;
    color: black;
    display: flex;
    flex-direction: row;
  }

  &__panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 400px;
    background-color: #FABC2A;
    justify-content: flex-start;
    padding: 50px 20px 0 30px;

    @media (max-width: 780px) {
      display: none;
    }

    &--summary {
      font-weight: bold;
      margin-bottom: 25px;
      font-size: 25px;
    }

    &--label {
      margin-bottom: 20px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 95%;
  }

  &__collapse {
    border-radius: 5px;
    border: solid 2px black;
    margin-bottom: 25px;
    transition: border 0.1s ease-out;

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      font-size: 20px;
      font-weight: 400;
      cursor: pointer;

      &.collapse {
        font-weight: 500;
      }
    }

    &__content {
      padding: 5px 20px 20px 20px;
    }

    &__container {
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.1s ease-out;
    }
  }

  &__collapse.collapse {
    border: solid 2px #FABC2A;
    transition: border 0.2s ease-out;
  }
}

.confidentiality-politic__collapse__container.expanded {
  max-height: fit-content;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
</style>