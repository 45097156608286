import { createRouter, createWebHistory } from 'vue-router';
import MainPage from '../views/mainpage/index.vue';
import HelpPage from '../views/help/index.vue';
import ConfidentialityPolitic from '../views/documents/ConfidentialityPolitic.vue';
import CguTerms from '../views/documents/CguTerms.vue';
import SelectHelp from '../views/help/SelectHelp.vue';
import DeleteAccount from '../views/help/DeleteAccount.vue';
import UpdateDeleteData from '../views/help/UpdateDeleteData.vue';
import SignalAccount from '../views/help/SignalAccount';
import BlockAccount from '../views/help/BlockAccount';
import ValidateEmail from '../views/validateEmail/index.vue';

const routes = [
  {
    path: '/',
    component: MainPage
  },
  {
    path: '/confidentiality',
    children: [
      {
        path: 'political',
        component: ConfidentialityPolitic
      },
      {
        path: 'cgu',
        component: CguTerms
      }
    ],
  },
  {
    path: '/help',
    component: HelpPage,
    children: [
      {
        path: '',
        component: SelectHelp
      },
      {
        path: 'deleteAccount',
        name: 'deleteAccount',
        component: DeleteAccount
      },
      {
        path: 'updateDeleteData',
        name: 'updateDeleteData',
        component: UpdateDeleteData
      },
      {
        path: 'signalAccount',
        name: 'signalAccount',
        component: SignalAccount
      },
      {
        path: 'blockAccount',
        name: 'blockAccount',
        component: BlockAccount
      }
    ],
  },
  {
    path: '/validate_email',
    component: ValidateEmail,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
