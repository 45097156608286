<template>
  <div class="help">
    <div class="help__header">
      <div
        class="help__fixed-header"
        @click="$router.push('/')"
      >
        <span class="help__fixed-header__logo">NOHYIA</span>
      </div>
      <span>Centre d'aide</span>
    </div>
    <div class="help__link">
      <span
        class="help__link--history first"
        @click="$router.push('/help')"
      >Centre d'aide</span>
      <span v-if="currentRoute">/</span>
      <span
        class="help__link--history last"
      >
        {{ currentRoute }}
      </span>
    </div>
    <div
      v-if="!currentRoute"
      class="help__description"
    >
      <span class="help__description__text">Vous avez un problème avec Nohyia ? Contactez l’assistance et dites-nous comment nous pouvons vous aider sur l'email suivant : <b>contact@nohyia.com</b>.</span>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'HelpPage',
  computed: {
    currentRoute() {
      if (this.$route.name === "deleteAccount") {
        return 'Supprimer son compte';
      } else if (this.$route.name === "updateDeleteData") {
        return 'Modifier ses informations';
      } else if (this.$route.name === "signalAccount") {
        return 'Signaler un compte ou une publication';
      } else if (this.$route.name === "blockAccount") {
        return 'Bloquer un compte';
      }

      return this.$route.name;
    }
  }
}
</script>

<style lang="scss">
.spacing {
  margin-top: 10px;
}

.help {
  &__fixed-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    &__logo {
      font-size: 40px;
      font-weight: bold;
      color: #FABC2A;
      cursor: pointer;

      @media (max-width: 650px) {
        font-size: 40px;
      }
    }
  }

  &__header {
    display: flex;
    align-items: flex-end;
    height: 180px;
    padding: 30px 50px;
    background-color: black;
    color: white;
    font-size: 40px;
    font-weight: bold;
    position: relative;
  }

  &__link {
    padding-top: 30px;
    padding-left: 50px;
    cursor: pointer;

    &--history {
      color: blue;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__description {
    padding-top: 30px;
    padding-left: 50px;
  }
}

.first {
  margin-right: 5px;
}

.last {
  margin-left: 5px;
}
</style>
