<template>
  <div class="select-help__list">
    <div
      v-for="(option, index) in helpOptions"
      :key="index"
      class="select-help__list__card"
      @click="$router.push({ name: option.route })"
    >
      <span>{{ option.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectHelp',
  data() {
    return {
      helpOptions: [
        { name: 'Supprimer son compte', route: 'deleteAccount' },
        { name: 'Modifier ses informations', route: 'updateDeleteData' },
        { name: 'Signaler un compte ou une publication', route: 'signalAccount' },
        { name: 'Bloquer un compte', route: 'blockAccount' },
      ],
    };
  }
}
</script>

<style lang="scss">
.select-help {
  &__list {
    padding: 50px;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &__card {
      border-radius: 5px;
      border: solid 2px black;
      margin-bottom: 25px;
      padding: 40px;
    }
  }
}
</style>