<template>
  <div class="delete-account__content">
    <span class="delete-account__content__title">Comment supprimer son compte Nohyia ?</span>
    <span class="delete-account__content__text">Vous avez un problème avec Nohyia ? Contactez l’assistance et dites-nous comment nous pouvons vous aider sur l'email suivant : <b>contact@nohyia.com</b>.</span>
    <span class="delete-account__content__text spacing">Pour supprimer votre compte Nohyia, suivez les étapes ci-dessous. Cela supprimera votre compte définitivement.</span><br/>

    <span class="delete-account__content__subtitle">Pour supprimer votre compte Nohyia sur IOS ou Android.</span>

    <span class="delete-account__content__text">
        -&nbsp;&nbsp;Connectez-vous sur votre compte Nohyia <br/>
        -&nbsp;&nbsp;Allez dans les paramètres du compte (depuis la page profil)<br/>
        -&nbsp;&nbsp;Appuyez sur « Supprimer le compte » tout en bas de la page<br/>
        -&nbsp;&nbsp;Appuyez sur « Confirmer » pour confirmer la suppression du compte<br/>
    </span>
  </div>
</template>
  
  <script>
  export default {
    name: 'DeleteAccountPage',
  }
  </script>
  
  <style lang="scss">
  .spacing {
    margin-top: 10px;
  }
  
  .delete-account {
    &__content {
      background-color: white;
      color: black;
      display: flex;
      flex-direction: column;
      padding: 30px 50px;
  
      &__title {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 10px;
      }
  
      &__subtitle {
        font-weight: bold;
        font-size: 15px;
        margin-bottom: 10px;
      }
  
      &__text {
        font-size: 14px;
      }
    }
  }
  </style>
  