<template>
  <div class="footer">
    <span class="footer__click-event" @click="goToTermOfUser">Conditions Générales d'Utilisation</span>
    <span class="footer__click-event" @click="goToConfidentiality">Politique de confidentialité</span>
    <span>© 2024 - All rights reserved</span>
  </div>
</template>

<script>
export default {
  name: 'FooterSection',
  methods: {
    goToConfidentiality() {
      this.$router.push('/confidentiality/political');
    },
    goToTermOfUser() {
      this.$router.push('/confidentiality/cgu');
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  padding: 15px 80px;
  background-color: black;
  color: white;
  font-size: 12px;
  width: -webkit-fill-available;

  @media (max-width: 650px) {
    padding: 15px 30px;
  }

  & > span {
    text-align: center;
  }

  &__click-event {
    cursor: pointer;
  }
}
</style>